/* General Styles */
body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #f5f5f5;
  color: #333;
  /* margin: 1rem; */
}

h1,
h2,
h3 {
  color: #4d7a9e;
  margin: 1rem;
}

h4 {
  /* margin: 0; */
  margin: 1rem;
}

.page-description {
  margin: 1rem;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #333;
}

.page-description p {
  margin-bottom: 10px;
}

.page-description p:last-child {
  margin-bottom: 0;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

th,
td {
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #e6e6e6;
  color: #4d7a9e;
}

/* Form Styles */
form {
  background-color: #fff;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #4d7a9e;
}

input[type="number"] {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
}

button[type="submit"] {
  background-color: #4d7a9e;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #3c5f7c;
}

/* Output Styles */
.outputs {
  margin-top: 1rem;
  background-color: #fff;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.outputs h3 {
  margin-top: 0;
}

.outputs table {
  margin-bottom: 3rem;
}

.outputs th {
  background-color: #e6e6e6;
  color: #4d7a9e;
}

.outputs td {
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;
}


.subform {
  margin-right: 3rem;
  align-items: center;
  text-align: left
}

.subform h3 {
  text-align: center;
}

.ref-table-header {
  background-color: #e6e6e6;
  color: #4d7a9e;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.ref-table-header:hover {
  background-color: #d9d9d9;
}

.ref-tables {
  display: flex;
  margin: 1rem;
  justify-content: center;
  gap: 2vw;
}


.ref-tables th,
.ref-tables td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.ref-tables th {
  background-color: #f2f2f2;
}


.ref-tables th,
.ref-tables td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.ref-tables th {
  background-color: #f2f2f2;
}

.collapse-icon {
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(180deg);
}

.ref-table p {
  margin-top: 0rem;
}

.ref-table {
  padding-top: 0rem;
}

.ref-tables {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.ref-tables>div {
  flex: 1 1 300px;
  /* Adjust the minimum width as needed */
}

@media (max-width: 768px) {
  .ref-tables {
    flex-direction: column;
  }
}

.chart-container {
  width: 85vw;
  height: 300px;
  margin: 0 auto;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ga-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f0f0f0;
  padding: 10px 20px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.ga-consent-banner p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.ga-consent-banner button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.ga-consent-banner button:hover {
  background-color: #0056b3;
}

.footer {
  background-color: #e6e6e6;
  color: black;
  /* font-weight: bold; */
  padding: 20px 0;
  text-align: center;
  margin-left: 0;
  margin-right: 0;
}


/* Donation footer container */
.donation-footer-container {
  margin-top: 20px;
}

/* Donation button styles */
.donation-button {
  padding: 10px 20px;
  font-size: 16px;
  
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.donation-button:hover {
  background-color: #0056b3;
}

